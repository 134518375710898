import { types } from "./mutations";
import * as Api from "@/services/files";
import { API_URL } from '../../../config/index'
const resource = `${API_URL}`
import route from "@/router.js"


function setLoading({ commit }, payload) {
    commit(types.SET_LOADING, payload);
}

async function getAllFiles({ commit, dispatch }) {
    commit(types.SET_LOADING, true);
    try {
        const response = await Api.getAll();
        // const {
        //     data: { data },
        // } = response;
        console.log("response.data.data", response.data.data);
        commit(types.SET_ALL_FILES, response.data.data);
    } catch (err) {
        dispatch('notifications/setNotification', { message: Object.values(err.response.data.errors)[0][0], type: 'error' }, { root: true })

    } finally {
        commit(types.SET_LOADING, false);
    }
}

async function getMyFiles({ commit, dispatch }) {
    commit(types.SET_LOADING, true);
    try {
        const response = await Api.getMyFiles();
        // const {
        //     data: { data },
        // } = response;
        commit(types.SET_MY_FILES, response.data.data);
    } catch (err) {
        dispatch('notifications/setNotification', { message: Object.values(err.response.data.errors)[0][0], type: 'error' }, { root: true })
    } finally {
        commit(types.SET_LOADING, false);
    }
}

async function downloadItem({ commit, dispatch }, payload) {
    commit(types.SET_LOADING, true);
    try {
        window.open(`${resource}/file/${payload}/download`)
        dispatch(
            "notifications/setNotification", { message: "تم تجهيز الملف بنجاح", type: "success" }, { root: true }
        );

        // route.push('/files')
    } catch (err) {
        dispatch('notifications/setNotification', { message: Object.values(err.response.data.errors)[0][0], type: 'error' }, { root: true })

    } finally {
        commit(types.SET_LOADING, false);
    }
}

async function uploadItem({ commit, dispatch }, payload) {
    console.log("payload", payload)
    const formData = payload;
    commit(types.SET_LOADING, true);
    try {
        const response = await Api.upload(formData);
        // eslint-disable-next-line no-unused-vars
        const data = response.data;
        dispatch(
            "notifications/setNotification", { message: "تم الرفع بنجاح", type: "success" }, { root: true }
        );
        route.push('/files')
    } catch (err) {
        dispatch('notifications/setNotification', { message: Object.values(err.response.data.errors)[0][0], type: 'error' }, { root: true })

    } finally {
        commit(types.SET_LOADING, false);
    }
}

export default {
    setLoading,
    getAllFiles,
    getMyFiles,
    downloadItem,
    uploadItem,
};