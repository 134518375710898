const dialog = {
    methods: {
        openDialog ({
            title = '',
            body = '',
            action = () => {},
            cssClass = '',
            text = '',
        }) {
            this.$store.dispatch('setText', { title, body })
            this.$store.dispatch('setAction', { action, cssClass, text })
            this.$store.dispatch('changeStatus', true)
        },
    },
}

export default dialog
