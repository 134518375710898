/* eslint-disable no-unused-vars */
import axios from 'axios'
import { API_URL } from '../../config'
// import router from '../../router/router'
// import { deleteAllCookies } from '../../Utils/cookies'

function setCookie(cname, cvalue, exdays) {
    const d = new Date()
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
    const expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

const Login = {
    state: {
        // isLoading: false,
        loginErrorMessage: null,
        loginSuccessful: false,
        token: localStorage.getItem('token'),
        userDataPermission: null,
        userAuth: '',
        userToken: '',
        userData: JSON.parse(localStorage.getItem('userData')),
        companies: [],
        loginData: {},
        successMessage: '',
        successMessageReset: '',

    },
    getters: {
        token(state) {
            return state.token
        },
    },
    mutations: {
        setCompaines(state, payload) {
            state.companies = payload
        },
        resetState(state) {
            state.isLoading = false
            state.loginErrorMessage = null
            state.loginSuccessful = false
            state.successMessage = ''
            state.successMessageReset = ''
        },
        // Logout
        logout() {
            localStorage.removeItem('token')
            localStorage.removeItem('userData')
            localStorage.removeItem('userPermissions')
            localStorage.removeItem('domain')
                // deleteAllCookies()
            document.location.href = '/authentication/fulllogin'
        },
        //  Set Token
        setToken(state, data) {
            localStorage.setItem('token', data.token)
            state.token = data.token
        },
    },
    actions: {
        async getCompaines({ commit, state, dispatch }, loginData) {
            commit('resetState')
            const userData = {
                email: loginData.replace(/\s+/g, ''),

            }
            await axios.post(`${API_URL}/company-by-email`, userData)
                .then((response) => {
                    state.isLoading = true
                    if (response.status === 200) {
                        // state.companies = response.data.data.companies
                        commit('setCompaines', response.data.data.companies)
                        console.log('state.companies', state.companies)
                    } else {
                        if (response.data.error_message) {
                            state.loginErrorMessage = response.data.error_message
                        } else if (response.errors.email) {
                            state.loginErrorMessage = response.data.errors.email[0]
                        }
                        state.isLoading = true
                    }
                })
                .catch(error => {
                    console.log('error', error.response.data.message)
                    state.loginErrorMessage = error.response.data.message
                    state.isLoading = true
                })
        },
        doLogin({ commit, state, dispatch }, loginData) {
            commit('resetState')
            const userData = {
                email: loginData.email.replace(/\s+/g, ''),
                password: loginData.password,
                company_id: loginData.company_id
            }
            axios.post(`${API_URL}/login`, userData)
                .then((response) => {
                    state.isLoading = true
                    if (response.status === 200) {
                        // console.log('response', response)
                        /**
                         * * converting roles to objects instead of array
                         */
                        // const permissions = {}
                        // const arrayOfRoles = response.data.role.permission
                        // arrayOfRoles.forEach(item => {
                        //     permissions[item.model_name] = {...item }
                        // })
                        localStorage.setItem('token', response.data.data.token)
                        localStorage.setItem('userData', JSON.stringify(response.data.data.user))
                        localStorage.setItem('companyData', JSON.stringify(response.data.data.company))
                        localStorage.setItem('userLang', 'ar')
                        localStorage.setItem('userPermissions', JSON.stringify(response.data.data.permissions))
                            // localStorage.setItem('userPermissions', JSON.stringify(permissions))
                        setCookie('token', response.data.data.token, 1000)
                        state.userData = response.data.data.user
                        state.userToken = response.data.data.token
                        state.userDataPermission = response.data.data.permissions
                            // dispatch('checkUserData')
                            // ! WE DON'T KNOW THE DOMAIN YET. I NEED IT IN THE RESPONSE.
                            // window.localStorage.setItem('domain', response.data.facility.url)
                        window.location.href = '/'
                    } else {
                        console.log('response', response)
                        if (response.data.error_message) {
                            state.loginErrorMessage = response.data.error_message
                        } else if (response.errors.email) {
                            state.loginErrorMessage = response.data.errors.email[0]
                        }
                        state.isLoading = true
                    }
                })
                .catch(error => {
                    console.log('error', error.response.data.message)
                    state.loginErrorMessage = error.response.data.message
                    state.isLoading = true
                })
        },
        doRegister({ commit, state, dispatch }, loginData) {
            commit('resetState')
            const userData = {
                password: loginData.password,
                token: loginData.token
            }
            axios.post(`${API_URL}/invite/accept`, userData)
                .then((response) => {
                    console.log('response :>> ', response);
                    state.isLoading = true
                    if (response.status === 200) {
                        // console.log('response', response)
                        /**
                         * * converting roles to objects instead of array
                         */
                        // const permissions = {}
                        // const arrayOfRoles = response.data.role.permission
                        // arrayOfRoles.forEach(item => {
                        //     permissions[item.model_name] = {...item }
                        // })
                        // localStorage.setItem('token', response.data.data.token)
                        // localStorage.setItem('userData', JSON.stringify(response.data.data.user))
                        // localStorage.setItem('companyData', JSON.stringify(response.data.data.company))
                        // localStorage.setItem('userLang', 'ar')
                        // localStorage.setItem('userPermissions', JSON.stringify(response.data.data.permissions))
                        //     // localStorage.setItem('userPermissions', JSON.stringify(permissions))
                        // setCookie('token', response.data.data.token, 1000)
                        // state.userData = response.data.data.user
                        // state.userToken = response.data.data.token
                        // state.userDataPermission = response.data.data.permissions
                            // dispatch('checkUserData')
                            // ! WE DON'T KNOW THE DOMAIN YET. I NEED IT IN THE RESPONSE.
                            // window.localStorage.setItem('domain', response.data.facility.url)
                        window.location.href = '/authentication/FullLogin'
                    } else {
                        console.log('response', response)
                        if (response.data.error_message) {
                            state.loginErrorMessage = response.data.error_message
                        } else if (response.errors.email) {
                            state.loginErrorMessage = response.data.errors.email[0]
                        }
                        state.isLoading = true
                    }
                })
                .catch(error => {
                    console.log('error', error.response.data.message)
                    state.loginErrorMessage = error.response.data.message
                    state.isLoading = true
                })
        },
    },
}

export default Login