export const types = {
    GET_ALL: 'GET_ALL',

    SET_ALL_ROLES: 'SET_ALL_ROLES',
    SET_ALL_PERMISSIONS: 'SET_ALL_PERMISSIONS',
    SET_ALL: 'SET_ALL',
    SET_ALL_BY_ID: 'SET_ALL_BY_ID',

    GET_ONE: 'GET_ONE',
    SET_ONE: 'SET_ONE',

    SET_LOADING: 'SET_LOADING'
}

const mutations = {
    [types.SET_LOADING](state, payload) {
        state.isLoading = payload
    },

    [types.SET_ALL_ROLES](state, payload) {
        state.listRoles = payload
    },

    [types.SET_ALL_PERMISSIONS](state, payload) {
        state.listPermissions = payload
    },

    [types.SET_ALL_BY_ID](state, payload) {
        state.listById = payload
    },
    [types.SET_ONE](state, payload) {
        state.item = payload
    }
}

export default mutations