<template>
  <v-form>
      <slot></slot>

      <v-btn large color="primary" type="submit" @click.prevent="handleSubmit" :loading="loading">{{type === 'create' ? $t('form.create') : $t('form.edit') }}</v-btn>
  </v-form>
</template>

<script>
export default {
    props: ['handleSubmit', 'type', 'loading']
}
</script>