const notification = {
  namespaced: true,
  state: () => ({
    message: '',
    show: false,
    is_success: true,
    is_error: false,
  }),
  mutations: {
    SET_NOTIFICATION (state, { message }) {
      state.message = message
    },
    SET_SHOW (state, { type, status }) {
        if (status === true) {
            state.show = true
        } else {
            state.show = false
        }

        if (type === 'success') {
            state.is_success = status
        }

        if (type === 'error') {
            state.is_error = status
        }
    },
  },
  actions: {
    setNotification ({ commit }, { message, type }) {
      commit('SET_SHOW', { type, status: true })
      commit('SET_NOTIFICATION', { message })
      setTimeout(() => {
        commit('SET_SHOW', { type, status: false })
      }, 2000)
    },
  },
}

export default notification
