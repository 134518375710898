export const types = {
    GET_ALL_FILES: 'GET_ALL_FILES',
    SET_ALL_FILES: 'SET_ALL_FILES',

    GET_MY_FILES: 'GET_MY_FILES',
    SET_MY_FILES: 'SET_MY_FILES',

    SET_ALL_BY_ID: 'SET_ALL_BY_ID',
    SET_ONE: 'SET_ONE',

    SET_LOADING: 'SET_LOADING'
}

const mutations = {
    [types.SET_LOADING](state, payload) {
        state.isLoading = payload
    },

    [types.SET_ALL_FILES](state, payload) {
        state.allFiles = payload
    },

    [types.SET_MY_FILES](state, payload) {
        state.myFiles = payload
    },

}

export default mutations