import axios from 'axios'
import { API_URL } from '../config/index'

const _axios = axios.create({
    baseURL: API_URL
})

_axios.interceptors.response.use((response) => {
        return response
    },
    function(error) {
        if (error.response.status === 401) {
            localStorage.removeItem('token')
            window.location = '/authentication/fulllogin'
        }
        return Promise.reject(error)
            .then(
                data => console.log(data)
            )
    }
)

export default _axios