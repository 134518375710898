import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import dialogMixin from "./mixins/dialog";
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import InstantSearch from "vue-instantsearch";
import axios from './services/Service'
import { API_URL } from './config/index.js'
// import * as Cookies from 'js-cookie'
// Language Plugin
import VueI18n from 'vue-i18n'
import en from './locales/en.json'
import ar from './locales/ar.json'

import AgoraRTC from 'agora-rtc-sdk'
 
Vue.use(AgoraRTC);

Vue.use(VueSkycons, {
    color: "#1e88e5",
});

Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.use(Vuebar);

Vue.use(VueI18n)
    // Ready translated locale messages
const language = {
    en: en,
    ar: ar,
}
let userLang = 'ar'
if (localStorage.getItem('userLang')) {
    userLang = localStorage.getItem('userLang')
}
// Create VueI18n instance with options
const i18n = new VueI18n({
        locale: userLang,
        messages: language,
    })
    // config file with base endpoint url
axios.defaults.baseURL = `${API_URL}`
const Lang = localStorage.getItem('userLang')
const userToken = localStorage.getItem('token')
if (userToken) {
    axios.defaults.headers.common.Authorization = 'Bearer ' + userToken
    axios.defaults.headers['Accept-Language'] = Lang
}
axios.defaults.headers.accept = 'application/json'
axios.defaults.headers['Content-type'] = 'application/json'

Vue.mixin({ mixins : [dialogMixin]})

new Vue({
    vuetify,
    store,
    router,
    i18n,
    render: (h) => h(App),
}).$mount("#app");