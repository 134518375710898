<template>
  <v-app id="materialpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
      <router-view />
      <notification />
      <Dialog />
  </v-app>

</template>

<script>
import Notification from "./components/Notification.vue";
import Dialog from "./components/CustomDialog.vue";

export default {
  name: 'App',

  components: {
    Notification,
    Dialog
  }

};
</script>
