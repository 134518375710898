const dialog = {
  state: () => ({
    show: false,
    title: '',
    body: '',
    saveButton: {
      action: () => {},
      class: '',
      text: '',
    },
  }),
  mutations: {
    SET_STATUS (state, payload) {
      state.show = payload
    },

    SET_TEXT (state, payload) {
      const { title, body } = payload
      state.title = title
      state.body = body
    },

    SET_ACTION (state, payload = {}) {
      const { action, class: cssClass, text } = payload
      // default values for confirmation button
      state.saveButton.action = action || (() => {})
      state.saveButton.class = cssClass || ''
      state.saveButton.text = text || 'confirm'
    },
  },
  actions: {
    changeStatus (context, payload) {
      context.commit('SET_STATUS', payload)
    },
    setText (context, payload) {
      context.commit('SET_TEXT', payload)
    },
    setAction (context, payload) {
      context.commit('SET_ACTION', payload)
    },
    fireAction ({ state }) {
      state.saveButton.action()
    },
  },
}

export default dialog
