export const types = {
    GET_ALL: 'GET_ALL',

    SET_ALL: 'SET_ALL',
    SET_ALL_BY_ID: 'SET_ALL_BY_ID',

    GET_ONE: 'GET_ONE',
    SET_ONE: 'SET_ONE',
    SET_ALL_ROLES: 'SET_ALL_ROLES',
    SET_ALL_PENDING: 'SET_ALL_PENDING',
    SET_LOADING: 'SET_LOADING'
}

const mutations = {
    [types.SET_LOADING](state, payload) {
        state.isLoading = payload
    },

    [types.SET_ALL](state, payload) {
        state.list = payload
    },

    [types.SET_ALL_BY_ID](state, payload) {
        state.listById = payload
    },
    [types.SET_ONE](state, payload) {
        state.item = payload
    },
    // [types.SET_ALL_ROLES](state, payload) {
    //     state.listRoles = payload
    // },
    [types.SET_ALL_PENDING](state, payload) {
        state.allPending = payload
    }
}

export default mutations