<template>
    <div>
        <p>{{label || ''}}</p>
        <v-text-field 
        :value="value"
        outlined
        single-line 
        @input="value => $emit('input', value)" />
    </div>
</template>

<script>
export default {
    name: 'BaseTextField',
    props: ['value', "label"],
}
</script>