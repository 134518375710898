import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    // This is for the scroll top when click on any router link
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0;

        if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        }

        return goTo(scrollTo);
    },
    // This is for the scroll top when click on any router link
    routes: [{
            path: "/authentication",
            component: () =>
                import ("@/layouts/blank-layout/Blanklayout"),
            children: [{
                    name: "FullLogin",
                    path: "fulllogin",
                    component: () =>
                        import ("@/views/authentication/FullLogin"),
                },
                {
                    name: "completeRegister",
                    path: "completeRegister/:token?",
                    component: () =>
                        import ("@/views/authentication/completeRegister"),
                },
                {
                    name: "FullRegister",
                    path: "fullregister",
                    component: () =>
                        import ("@/views/authentication/FullRegister"),
                },
                {
                    name: "Error",
                    path: "error",
                    component: () =>
                        import ("@/views/authentication/Error"),
                },
            ],
        },
        {
            path: "/",
            redirect: "/",
            // meta: { requiresAuth: true },
            component: () =>
                import ("@/layouts/full-layout/Layout"),
            children: [{
                    name: "Analytical",
                    path: "/",
                    meta: { requiresAuth: true },
                    component: () =>
                        import ("@/views/dashboards/analytical/Analytical"),
                },
                // unAuthorized Page
                {
                    path: 'notAuthorized',
                    name: 'notAuthorized',
                    component: () =>
                        import ('@/views/authentication/NotAuthorized'),
                },
                // members 
                {
                    name: "Members",
                    path: 'members/active',
                    component: () =>
                        import ('@/views/members/activatedMembers/List'),
                    meta: { requiresAuth: true, permissions: 'الاعضاء' }
                },
                {
                    name: "membersForm",
                    path: 'memberForm/:id?',
                    component: () =>
                        import ('@/views/members/activatedMembers/Form'),
                    meta: { requiresAuth: true, permissions: 'الاعضاء', actions: 1 }
                },
                // pending members 
                {
                    name: "Pending Members",
                    path: 'members/pending',
                    component: () =>
                        import ('@/views/members/pendingMembers/List'),
                    meta: { requiresAuth: true, permissions: 'الاعضاء' }
                },
                // Files 
                {
                    name: "Files",
                    path: 'files',
                    component: () =>
                        import ('@/views/files/List'),
                    meta: { requiresAuth: true }
                },
                {
                    name: "Private Files",
                    path: 'privateFiles',
                    component: () =>
                        import ('@/views/files/PrivateList'),
                    meta: { requiresAuth: true }
                },
                {
                    name: "filesForm",
                    path: 'filesForm',
                    component: () =>
                        import ('@/views/files/Form'),
                    meta: { requiresAuth: true }
                },
                {
                    name: "Roles",
                    path: "roles",
                    meta: { requiresAuth: true },
                    component: () =>
                        import ('@/layouts/empty-layout/EmptyLayout'),
                    children: [{
                            path: '/roles',
                            component: () =>
                                import ('@/views/roles/List'),
                            meta: { requiresAuth: true, permissions: 'الرتب' }
                        },
                        {
                            path: 'roleForm/:id?',
                            component: () =>
                                import ('@/views/roles/Form'),
                            meta: { requiresAuth: true, permissions: 'الرتب', actions: 1 }
                        }
                    ],
                },
                {
                    name: "Meetings",
                    path: "meetings",
                    meta: { requiresAuth: true },
                    component: () =>
                        import ('@/layouts/empty-layout/EmptyLayout'),
                    children: [{
                            path: '/meetings',
                            component: () =>
                                import ('@/views/meetings/List'),
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/meeting/:id?',
                            component: () =>
                                import ('@/views/meetings/Meeting'),
                            meta: { requiresAuth: true }
                        },
                        {
                            path: 'meetingsForm/:id?',
                            component: () =>
                                import ('@/views/meetings/Form'),
                            meta: { requiresAuth: true }
                        }
                    ],
                },
            ],
        },
        {
            path: "*",
            component: () =>
                import ("@/views/authentication/Error"),
        },
        // { path: '*', redirect: '/' },
    ],
});

// import NProgress from "nprogress";

// /**
//  * ? check if the user has any permissions on a spcific model,
//  * ? and return "true" if there is atleast one, otherwise return "false"
//  * * return Boolean
//  */
// const can = (modelName) => {
//         /**
//          *  * if the modelName is an array
//          */
//         console.log('modelName', modelName)
//         if (Array.isArray(modelName)) {

//             for (let index = 0; index < modelName.length; index++) {
//                 const { read, update, delete: remove, add, approval } = JSON.parse(localStorage.getItem('userPermissions'))[modelName[index]]
//                 if (read || update || remove || add || approval) {
//                     return true
//                 }
//             }

//             return false
//         } else {
//             /**
//              * * if the modelName is just a string
//              */
//             const { read, update, delete: remove, add, approval } = JSON.parse(localStorage.getItem('userPermissions')).find(item => {
//                 return item.name === modelName
//             })
//             if (!read && !update && !remove && !add && !approval) {
//                 return false
//             }
//             return true
//         }
//     }
//     /**
//      * ? check if the user has the right permession in the this model for the spcific action
//      * * return Boolean
//      */
// const canActions = (modelName, action) => {
//     const { add, update, read } = JSON.parse(localStorage.getItem('userPermissions')).find(item => {
//         return item.name === modelName
//     })
//     if (action === 'add' && add) {
//         return true
//     } else if (action === 'update' && (update || read)) {
//         return true
//     } else {
//         return false
//     }
// }

// router.beforeResolve((to, from, next) => {
//     // If this isn't an initial page load.
//     if (to.name) {
//         // Start the route progress bar.
//         NProgress.start(800);
//     }
//     next();
// });
// router.beforeEach(async(to, from, next) => {
//     const token = localStorage.getItem('token')
//         /**
//          * * is this router require a token ?
//          */
//     if (to.meta.requiresAuth) {
//         /**
//          * * if the user does not has a token, redirect him to the login page
//          */
//         if (!token) {
//             document.location.href = '/authentication/fulllogin'
//         } else {
//             /**
//              * * if the user has a token
//              */
//             /**
//              * * check permissions
//              */
//             if (to.meta.permissions && can(to.meta.permissions)) {
//                 /**
//                  * * if the page does not has actions (list page)
//                  */
//                 if (!to.meta.actions) {
//                     return next()
//                 } else {
//                     /**
//                      * * if page has actions and the action is "update" and the user has it
//                      */
//                     if (to.params.id && canActions(to.meta.permissions, 'update')) {
//                         next()
//                             /**
//                              * * if page has actions and the action is "add" and the user has it
//                              */
//                     } else if (!to.params.id && canActions(to.meta.permissions, 'add')) {
//                         next()
//                     } else {
//                         /**
//                          * * if the page has actions but the user does not has it,
//                          * * then redirect him to "not authorized" page
//                          */
//                         next({
//                             path: 'notAuthorized',
//                         })
//                     }
//                 }
//             } else if (!to.meta.permissions) {
//                 next()
//             } else {
//                 next({
//                     path: 'notAuthorized',
//                 })
//             }
//             /**
//              * * end of check permissions
//              */
//         }
//     } else {
//         next()
//     }
// })
// router.afterEach(() => {
//     // Complete the animation of the route progress bar.
//     NProgress.done();
// });

export default router;