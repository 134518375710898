<template>
  <div>
    <p>{{label || ''}}</p>
    <v-combobox
      :value="value"
      :hide-no-data="!search"
      :search-input.sync="search"
      hide-selected
      :hide-spin-buttons="true"
      multiple
      small-chips
      outlined
      single-line
      @input="handleInput"
    >
      <template v-slot:no-data>
        <v-list-item>
          <span class="subheading">إنشاء </span>
          <v-chip
            :color="`red lighten-3`"
            label
            small
          >
            {{ search }}
          </v-chip>
        </v-list-item>
      </template>
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip
          v-bind="attrs"
          :color="`blue lighten-3`"
          :input-value="selected"
          label
          small
        >
          <span class="pr-2">
            {{ item }}
          </span>
          <v-icon
            small
            @click="parent.selectItem(item); handleDelete(item)"
          >
            $delete
          </v-icon>
        </v-chip>
      </template>
      <template v-slot:item="{ index, item }">
        <v-text-field
          v-if="editing === item"
          v-model="editing.text"
          autofocus
          flat
          background-color="transparent"
          hide-details
          solo
          @keyup.enter="edit(index, item)"
        />
        <v-chip
          v-else
          :color="`${item.color} lighten-3`"
          dark
          label
          small
        >
          {{ item.text }}
        </v-chip>
        <v-spacer />
        <v-list-item-action @click.stop>
          <v-btn
            icon
            @click.stop.prevent="edit(index, item)"
          >
            <v-icon>{{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
          </v-btn>
        </v-list-item-action>
      </template>
    </v-combobox>
  </div>
</template>

<script>
  export default {
    name: 'CustomComobox',
    props: {
      value: {
        type: Array,
        default: () => ([]),
      },
      label : {
          type : String,
          default : ''
      }
    },
    data: () => ({
      activator: null,
      attach: null,
      colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
      editing: null,
      editingIndex: -1,
      menu: false,
      model: [
      ],
      x: 0,
      search: null,
      y: 0,
      counter: 0,
    }),
    methods: {
      handleDelete (value) {
        this.$emit('input', this.value.filter(item => item !== value))
      },
      edit (index, item) {
        if (!this.editing) {
          this.editing = item
          this.editingIndex = index
        } else {
          this.editing = null
          this.editingIndex = -1
        }
      },
      handleInput (value) {
          this.$emit('input', value)
      },
    },
  }
</script>
