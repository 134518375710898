import { types } from "./mutations";
import * as Api from "@/services/meetings";
// import * as authApi from "@/services/auth";
import route from "@/router.js"
import moment from 'moment'


function setLoading({ commit }, payload) {
    commit(types.SET_LOADING, payload);
}

async function getAllData({ commit, dispatch }) {
    commit(types.SET_LOADING, true);
    try {
        const response = await Api.getAll();
        const data = response.data.data.data.map(i => {
            i.date = moment(i.date).format('YYYY-MM-DD HH:mm A')
            return i
           })
           console.log('data', data)
        commit(types.SET_ALL, data);
    } catch (err) {
        dispatch('notifications/setNotification', { message: Object.values(err.response.data.errors)[0][0], type: 'error' }, { root: true })
    } finally {
        commit(types.SET_LOADING, false);
    }
}
async function getById({ commit, dispatch }, payload) {
    commit(types.SET_LOADING, true);
    try {
        const response = await Api.getById(payload);
        const {
            data: { data },
        } = response;
        console.log('data :>> ', data);

        commit(types.SET_ONE, data);
    } catch (err) {
        dispatch('notifications/setNotification', { message: Object.values(err.response.data.errors)[0][0], type: 'error' }, { root: true })
    } finally {
        commit(types.SET_LOADING, false);
    }
}
async function joinMeeting({ commit, dispatch }, payload) {
    commit(types.SET_LOADING, true);
    try {
        const response = await Api.joinMeeting(payload);
        const {
            data: { data },
        } = response;
        console.log('data :>> ', data);

        commit(types.SET_ONE, data);
    } catch (err) {
        dispatch('notifications/setNotification', { message: Object.values(err.response.data.errors)[0][0], type: 'error' }, { root: true })
    } finally {
        commit(types.SET_LOADING, false);
    }
}

async function createItem({ commit, dispatch }, payload) {
    commit(types.SET_LOADING, true);
    try {
        const response = await Api.create(payload);
        const {
            // eslint-disable-next-line no-unused-vars
            data
        } = response;

        dispatch('notifications/setNotification', { message: 'تم الإضافة بنجاح', type: 'success' }, { root: true })

        route.push('/meetings')
    } catch (err) {
        console.log('err.response :>> ', err.response);
        console.log('Object.values(err.response.data.errors)[0][0] :>> ', Object.values(err.response));
        dispatch('notifications/setNotification', { message: Object.values(err.response.data.errors)[0][0], type: 'error' }, { root: true })
    } finally {
        commit(types.SET_LOADING, false);
    }
}
async function updateItem({ commit, dispatch }, payload) {
    const { id, formData } = payload
    commit(types.SET_LOADING, true);
    try {
        const response = await Api.update(id, formData);
        // eslint-disable-next-line no-unused-vars
        const data = response.data
        dispatch('notifications/setNotification', { message: 'تم التعديل بنجاح', type: 'success' }, { root: true })
        route.push('/meetings')
    } catch (err) {
        dispatch('notifications/setNotification', { message: Object.values(err.response.data.errors)[0][0], type: 'error' }, { root: true })
    } finally {
        commit(types.SET_LOADING, false);
    }
}
async function submitVVote({ commit, dispatch }, payload) {
    console.log('payload :>> ', payload);
    commit(types.SET_LOADING, true);
    try {
        const response = await Api.vote(payload);
        // console.log('response :>> ', response);
        // eslint-disable-next-line no-unused-vars
        const data = response.data
        dispatch('notifications/setNotification', { message: 'تم التصويت بنجاح', type: 'success' }, { root: true })
        // route.push('/meetings')
    } catch (err) {
        dispatch('notifications/setNotification', { message: Object.values(err.response.data.errors)[0][0], type: 'error' }, { root: true })

    } finally {
        commit(types.SET_LOADING, false);
    }
}

async function deleteItem({ commit, dispatch, state }, id) {
    commit(types.SET_LOADING, true);

    try {
        const response = await Api.remove(id);
        // eslint-disable-next-line no-unused-vars
        const data = response.data
        dispatch('notifications/setNotification', { message: 'تم الحذف بنجاح ', type: 'success' }, { root: true })

        commit(types.SET_ALL, state.list.filter(item => item.id !== id));
        // route.push('/plans')
    } catch (err) {
        dispatch('notifications/setNotification', { message: Object.values(err.response.data.errors)[0][0], type: 'error' }, { root: true })
    } finally {
        commit(types.SET_LOADING, false);
    }
}

export default { setLoading, getAllData, getById, createItem, updateItem, deleteItem, submitVVote, joinMeeting};